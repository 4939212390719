//
import 'babel-polyfill'
import React, { Component } from 'react'
import { StepContext } from '../Step'
import StepNavigator, { StepNavigatorContext } from '../StepNavigator'

import { Form, FormSpy } from 'react-final-form'
import MultiStepFormContext from './MultiStepFormContext'

/**
 * MultiStep State
 */

/**
 */
export default class MultiStepForm extends Component {
    renderCtx
    state = {
        stepData: {},
    }

    constructor(props) {
        super(props)

        this.renderCtx = {
            render: this.renderStep.bind(this),
        }

        const { initialValues } = props
        let sessionData = {}

        if (!props.dontPersist) {
            const key = this.getStorageKey()
            if (key) {
                const data = window.sessionStorage.getItem(key)
                if (data) {
                    try {
                        sessionData = JSON.parse(data)
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
        }

        const hasInitialValues =
            initialValues && Object.getOwnPropertyNames(initialValues).length
        if (
            Object.getOwnPropertyNames(sessionData).length ||
            hasInitialValues
        ) {
            this.state.stepData = { ...(sessionData ? sessionData : {}) }

            if (initialValues) {
                for (const stepName of Object.keys(initialValues)) {
                    if (!this.state.stepData[stepName]) {
                        this.state.stepData[stepName] = {}
                    }

                    if (hasInitialValues) {
                        this.state.stepData[stepName] = {
                            ...(initialValues[stepName]
                                ? initialValues[stepName]
                                : {}),
                            ...this.state.stepData[stepName],
                        }
                    }
                }
            }
        }

        this.submitStep = this.submitStep.bind(this)
        this.submitFullForm = this.submitFullForm.bind(this)
        this.onNavigateTo = this.onNavigateTo.bind(this)
        this.onChange = this.onChange.bind(this)
        this.renderStep = this.renderStep.bind(this)
    }

    getStorageKey() {
        const { id } = this.props

        if (id) {
            return `form${id}`
        }

        return null
    }

    persistFormData() {
        const { dontPersist } = this.props
        const { stepData } = this.state

        if (dontPersist) {
            return
        }

        const key = this.getStorageKey()
        if (key) {
            window.sessionStorage.setItem(key, JSON.stringify(stepData))
        }
    }

    submitStep(step, values, navigatorContext) {
        const {
            props: { name, last = false, onSubmit: stepOnSubmit },
        } = step

        return new Promise((resolve, reject) => {
            this.setState(
                (state) => {
                    state['stepData'][name] = values
                },
                async () => {
                    const { onSubmit } = this.props

                    this.persistFormData()

                    const isLast =
                        typeof last === 'function' ? last(values) : last

                    if (isLast && onSubmit) {
                        let result = this.submitFullForm()
                        if (result && typeof result.then === 'function') {
                            result = await result
                        }

                        if (typeof result === 'number') {
                            /* $FlowFixMe */
                            navigatorContext.navigateTo(result)
                        } else if (!result) {
                            /* $FlowFixMe */
                            navigatorContext.nextStep()
                        } else {
                            return resolve(result)
                        }
                    }

                    if (stepOnSubmit) {
                        let result = stepOnSubmit(values, onSubmit)

                        if (result && typeof result.then === 'function') {
                            result = await result
                        }

                        if (
                            result &&
                            typeof result === 'object' &&
                            result?.navigateTo
                        ) {
                            navigatorContext.navigateTo(result?.navigateTo)
                            resolve(result?.navigateTo)
                        }

                        if (result !== -1) {
                            navigatorContext.nextStep()
                        } else {
                            reject()
                        }
                    } else {
                        navigatorContext.nextStep()
                    }
                }
            )
        })
    }

    async submitFullForm() {
        const result = await this.props.onSubmit(this.state.stepData)
        const { dontPersist } = this.props

        if (!dontPersist) {
            // remove form data from sessionStorage
            const key = this.getStorageKey()
            if (key) {
                window.sessionStorage.removeItem(key)
            }
        }

        return result
    }

    async onNavigateTo(step) {
        const children = React.Children.toArray(this.props.children)

        if (children[step]) {
            const {
                props: { onNavigate },
            } = children[step]

            if (onNavigate) {
                return await onNavigate(step, this.state.stepData)
            }
        }

        return null
    }

    wrapInDiv(body, className) {
        if (className && className.length > 0) {
            return <div className={className}>{body}</div>
        }
        return body
    }

    /**
     * Auto-save all the form changes
     * @param name
     * @param values
     */
    onChange(name, values) {
        const key = this.getStorageKey()
        if (key) {
            const { stepData } = this.state
            const res = { ...stepData }
            if (!res[name]) {
                res[name] = { ...values }
            } else {
                res[name] = {
                    ...res[name],
                    ...values,
                }
            }

            const { dontPersist } = this.props
            if (!dontPersist) {
                window.sessionStorage.setItem(key, JSON.stringify(res))
            }
        }
    }

    renderStep(body, step) {
        const { name, className } = step.props

        // check if it is a FormStep
        if (name) {
            const { className: formClassName } = this.props
            const initialValues =
                this.state.stepData[name] && !step.props.forceInitialValues
                    ? this.state.stepData[name]
                    : step.props.initialValues

            return this.wrapInDiv(
                <StepNavigatorContext.Consumer>
                    {(navigatorContext) => (
                        <Form
                            onSubmit={(values) =>
                                this.submitStep(
                                    step,
                                    values,
                                    navigatorContext
                                ).catch(
                                    (err) =>
                                        err &&
                                        console.log(
                                            'error submitting step:',
                                            err
                                        )
                                )
                            }
                            initialValues={initialValues}
                            render={(form) => {
                                return (
                                    <form
                                        onSubmit={form.handleSubmit}
                                        className={formClassName}
                                        autoComplete="off"
                                    >
                                        <FormSpy
                                            onChange={({ values }) =>
                                                this.onChange(name, values)
                                            }
                                        />

                                        {body(form)}
                                    </form>
                                )
                            }}
                        />
                    )}
                </StepNavigatorContext.Consumer>,
                className
            )
        } else {
            return this.wrapInDiv(body, className)
        }
    }

    render() {
        const { children, initialStep, trackingKey, disableAutocomplete } =
            this.props
        return (
            <MultiStepFormContext.Provider
                value={{
                    values: this.state.stepData,
                    resetForm: () => {
                        this.setState({ stepData: {} })
                    },
                    disableAutocomplete,
                }}
            >
                <StepContext.Provider value={this.renderCtx}>
                    <StepNavigator
                        initialStep={initialStep}
                        onNavigate={this.onNavigateTo}
                        trackingKey={trackingKey}
                    >
                        {children}
                    </StepNavigator>
                </StepContext.Provider>
            </MultiStepFormContext.Provider>
        )
    }
}
