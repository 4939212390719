//
import { Component, createContext, useContext } from 'react'
import * as React from 'react'
import StepRenderer from './StepRenderer'
import history from 'components/frontend/history'

/**
 * StepNavigator Properties
 */

/**
 * StepNavigator State
 */

export const StepNavigatorContext = createContext({
    currentStep: 0,
    nextStep: () => {},
    prevStep: () => {},
    navigateTo: (step) => {},
})

export function useStepNavigator() {
    return useContext(StepNavigatorContext)
}

/**
 */
export default class StepNavigator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentStep: props.initialStep ? props.initialStep : 0,
            totalSteps: this.stepsCount(),
            nextStep: this.nextStep.bind(this),
            prevStep: this.prevStep.bind(this),
            navigateTo: this.navigateTo.bind(this),
        }

        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
        this.navigateTo = this.navigateTo.bind(this)
        this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialStep !== prevProps.initialStep) {
            this.setState({
                currentStep: this.props.initialStep,
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        /* $FlowFixMe */
        state.totalSteps = props.children.length
        return state
    }

    nextStep() {
        let { currentStep, totalSteps } = this.state

        if (currentStep < totalSteps - 1) {
            currentStep++
        }

        this.handleStepChange(currentStep)
    }

    prevStep() {
        let { currentStep } = this.state

        if (currentStep > 0) {
            currentStep--
        }

        this.handleStepChange(currentStep)
    }

    navigateTo(step) {
        let { totalSteps } = this.state

        if (step >= 0 && step < totalSteps) {
            this.handleStepChange(step)
        }
    }

    updateCurrentLocation() {
        const { currentStep } = this.state
        const { trackingKey, children } = this.props

        if (trackingKey) {
            let key = currentStep

            if (Array.isArray(children) && children[currentStep]) {
                const { props: { name } = {} } = children[currentStep]

                if (name) {
                    key = name
                }
            }

            let path = location.pathname + `?ul=${trackingKey}-${key}`
            if (location.hash) {
                path += location.hash
            }

            history.push(path)
        }
    }

    handleStepChange(currentStep) {
        const { onNavigate } = this.props

        if (onNavigate) {
            onNavigate(currentStep).then((newStep) => {
                this.setState(
                    {
                        currentStep: newStep ? newStep : currentStep,
                    },
                    this.updateCurrentLocation
                )
            })
        } else {
            this.setState(
                {
                    currentStep,
                },
                this.updateCurrentLocation
            )
        }
    }

    /**
     * @returns {number}
     */
    stepsCount() {
        /* $FlowFixMe */
        return this.props.children.length
    }

    render() {
        const { currentStep } = this.state

        return (
            <StepNavigatorContext.Provider value={this.state}>
                <StepRenderer activeStep={currentStep}>
                    {this.props.children}
                </StepRenderer>
            </StepNavigatorContext.Provider>
        )
    }
}
