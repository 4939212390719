//
import React, { Component, createContext } from 'react'

/**
 * Used to provide custom step body render method
 * @type {{Provider, Consumer}}
 */
/* $FlowFixMe */
export const StepContext = createContext({
    render: (body) => body,
})

class Step extends Component {
    static contextType = StepContext

    static defaultProps = {
        initialValues: undefined,
        onNavigate: undefined,
    }

    componentDidMount() {
        const { onNavigate } = this.props

        if (onNavigate) {
            onNavigate()
        }
    }

    render() {
        const { children } = this.props
        return this.context.render(children, this)
    }
}

export default Step
