import React from 'react'

export function mapChildren(children, func, context) {
    let index = 0

    let result = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child
        }

        return func.call(context, child, index++)
    })

    return result
}
