//
import { Component } from 'react'
import * as React from 'react'
import { mapChildren } from 'utils/children'

/**
 * StepController Properties
 */

/**
 * StepController State
 */

/**
 *
 */
export default class StepRenderer extends Component {
    /**
     * @param step
     * @param idx
     * @returns {*}
     */
    renderStepBody(step, idx) {
        const { activeStep } = this.props
        return idx === activeStep ? step : null
    }

    render() {
        const { children } = this.props
        return mapChildren(children, this.renderStepBody, this)
    }
}
